<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        
    <div class="m-2" style="margin-bottom:70px !important;">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />

        <v-row :style="`padding-inline-start:35px;direction:`+lang.dir">
            <v-col
            cols="12"
            md="2"
            sm="12">
            <label>{{ lang.start_date }}</label>
                <b-form-input placeholder="End Date" style="background: rgb(195, 217, 255);" class="inborder" type="date" v-model="sdate"></b-form-input>
            </v-col>
            <v-col
            cols="12"
            md="2"
            sm="12">
            <label>{{ lang.end_date }}</label>
                <b-form-input placeholder="Start Date" style="background: rgb(195, 217, 255);" class="inborder" type="date" v-model="edate"></b-form-input>
            </v-col>
            <v-col cols="12" md="2" sm="12">
                <label></label>
              <b-input-group>
                <b-button variant="light" style="padding-top:2px !important;padding-bottom:2px !important;background:blue;color:#fff;width:75px;"  @click="getReport()">{{lang.search}}</b-button>
              </b-input-group>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="text-right" style="display:none">
                <b-button variant="light" style="width:145px;margin-right:9px" class="mytab mytab1 mt-3" @click="exportE('e')">{{lang.export_excel}}</b-button>
                <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">{{ lang.print_pagexport_pdfe }}</b-button>
            </v-col>
        </v-row>
        <v-row :style="`direction:`+lang.dir">
            <v-col cols="12" md="12" sm="12" style="padding-inline:40px;">
                <div style="direction:rtl;display:flex;flex-direction: row;justify-content:start;"><v-simple-checkbox v-model="removeZero" /> {{ lang.hide_zero_accounts }}</div>
            </v-col>
            <v-col cols="12" md="12" sm="12" style="padding-inline:40px;">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="10" class="text-center backBlack">{{ lang.trial_balance }}</th>
                        </tr>
                        <tr>
                            <th class="text-center"></th>
                            <th class="text-center"></th>
                            <th class="text-center" colspan="2">{{lang.openingbalance}}</th>
                            <th class="text-center" colspan="2">{{lang.transaction}}</th>
                            <th class="text-center" colspan="2">{{lang.trial_in_balances}}</th>
                            <th class="text-center" colspan="2">{{ lang.trial_in_totals }}</th>
                            <th class="text-center" colspan="2">{{ lang.balances }}</th>
                        </tr>
                        <tr>
                            <th class="text-center backBlack">{{lang.account}}</th>
                            <th class="text-center backBlack">{{lang.description}}</th>
                            <th class="text-center backBlack">{{lang.depit}}</th>
                            <th class="text-center backBlack">{{lang.credit}}</th>
                            <th class="text-center backBlack">{{lang.depit}}</th>
                            <th class="text-center backBlack">{{lang.credit}}</th>
                            <th class="text-center backBlack">{{lang.depit}}</th>
                            <th class="text-center backBlack">{{lang.credit}}</th>
                            <th class="text-center backBlack">{{lang.depit}}</th>
                            <th class="text-center backBlack">{{lang.credit}}</th>
                            <th class="text-center backBlack">{{lang.depit}}</th>
                            <th class="text-center backBlack">{{lang.credit}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in accountrowses" :key="index">
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{item.classid}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{item[`name`+lang.langname]}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.oDebit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.oCredit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.Debit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.Credit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.bDebit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.bCredit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.tDebit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.tCredit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.sDebit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.sCredit,2),true)}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th class="backEmpty"></th>
                            <th class="backEmpty"></th>
                            <th class="backGreen">{{ $convertToComa($RoundNums(totals.oDebit,2),true) }}</th>
                            <th class="backRed">{{ $convertToComa($RoundNums(totals.oCredit,2),true) }}</th>
                            <th class="backGreen">{{ $convertToComa($RoundNums(totals.Debit,2),true) }}</th>
                            <th class="backRed">{{ $convertToComa($RoundNums(totals.Credit,2),true) }}</th>
                            <th class="backGreen">{{ $convertToComa($RoundNums(totals.bDebit,2),true) }}</th>
                            <th class="backRed">{{ $convertToComa($RoundNums(totals.bCredit,2),true) }}</th>
                            <th class="backGreen">{{ $convertToComa($RoundNums(totals.tDebit,2),true) }}</th>
                            <th class="backRed">{{ $convertToComa($RoundNums(totals.tCredit,2),true) }}</th>
                            <th class="backGreen">{{ $convertToComa($RoundNums(totals.sDebit,2),true) }}</th>
                            <th class="backRed">{{ $convertToComa($RoundNums(totals.sCredit,2),true) }}</th>
                        </tr>
                        <tr>
                            <th colspan="12" class="backGreen" v-if="btotals == 0"> {{lang.balance}} : {{btotals}} </th>
                            <th colspan="12" class="backRed" v-if="btotals != 0"> {{lang.balance}} : {{btotals}} </th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-col>
        </v-row>
        <Footer />
    </div>
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import ExportsPrint from '@/components/exports-print.vue';
export default {
  components: { breadCrumbs ,TabsComp, HeaderPortrate,Footer,ExportsPrint},
    data()
     {
        return {
            sdate: new Date().toISOString().substring(0, 10),
            edate: new Date().toISOString().substring(0, 10),
            accountrows: [],
            accounts: [],
            account: 0,
            account_name: '',
            removeZero: true,
            branchid: 0,
            totals: {
                oDebit: 0,
                oCredit: 0,
                Debit: 0,
                Credit: 0,
                bDebit: 0,
                bCredit: 0,
                tDebit: 0,
                tCredit: 0,
                sDebit: 0,
                sCredit: 0,
            }
        }
    },
    computed: {
        totalsa: function(){
            let t = {
                oDebit: 0,
                oCredit: 0,
                Debit: 0,
                Credit: 0,
                bDebit: 0,
                bCredit: 0,
                tDebit: 0,
                tCredit: 0,
                sDebit: 0,
                sCredit: 0,
            }
            for(let i=0;i<this.accountrowses.length;i++){
                if(this.accountrowses[i].type == 2)
                {
                    t.oDebit = this.$RoundNums(Number(t.oDebit) + Number(this.accountrowses[i].oDebit),4);
                    t.oCredit = this.$RoundNums(Number(t.oCredit) + Number(this.accountrowses[i].oCredit),4);
                    t.Debit = this.$RoundNums(Number(t.Debit) + Number(this.accountrowses[i].Debit),4);
                    t.Credit = this.$RoundNums(Number(t.Credit) + Number(this.accountrowses[i].Credit),4);
                    t.bDebit = this.$RoundNums(Number(t.bDebit) + Number(this.accountrowses[i].bDebit),4);
                    t.bCredit = this.$RoundNums(Number(t.bCredit) + Number(this.accountrowses[i].bCredit),4);
                    t.tDebit = this.$RoundNums(Number(t.tDebit) + Number(this.accountrowses[i].tDebit),4);
                    t.tCredit = this.$RoundNums(Number(t.tCredit) + Number(this.accountrowses[i].tCredit),4);
                    t.sDebit = this.$RoundNums(Number(t.sDebit) + Number(this.accountrowses[i].sDebit),4);
                    t.sCredit = this.$RoundNums(Number(t.sCredit) + Number(this.accountrowses[i].sCredit),4);
                }
            }
            return t;
        },
        accountrowses: function(){
            let t = [];
            for(const item in this.accountrows){
                if(this.removeZero == true){
                    // console.log(Number(this.accountrows[item].bDebit) != 0 || Number(this.accountrows[item].bCredit) != 0)
                    if(
                        Number(this.accountrows[item].bDebit) != 0 || Number(this.accountrows[item].bCredit) != 0
                    ){
                        t.push(this.accountrows[item])
                    }
                }else{
                    t.push(this.accountrows[item])
                }
            }
            return t;
        },
        btotals: function(){
            // let t = 0;
            // for(let i=0;i<this.accountrows.length;i++){
            //     if(this.accountrowses[i].type == 2)
            //     {
            //         t = parseFloat(t) + parseFloat(this.accountrows[i].bDebit) - parseFloat(this.accountrows[i].bCredit);
            //     }
            // }
            // t = this.$RoundNum(t);
            // if(t < 0){
            //     return "("+parseFloat(t)*-1+")";
            // }else{
            //     return t;
            // }
            // let t = 0;
            return this.$RoundNums(Number(this.totals.tDebit) - Number(this.totals.tCredit),2);
            // if(parseFloat(this.totals.tDebit) == parseFloat(this.totals.tCredit)) return 0;
            // if(parseFloat(this.totals.tDebit) > parseFloat(this.totals.tCredit)) return this.totals.tDebit;
            // if(parseFloat(this.totals.tCredit) > parseFloat(this.totals.tDebit)) return this.totals.tCredit;
            // return 0
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return {
                    text: this.lang.account_statement,
                    disabled: true,
                    to: '/reports/statment-report',
                }
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
                const cM = localStorage.getItem('currentMenu');
                if(value.main.index == cM){
                    cMenu = value.sub;
                }else if(cM == null && value.main.index == 1){
                    cMenu = value.sub;
                }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        headers: function(){
            return '';
        }
    },
    created() {
    //    this.getReport();
       this.getRep();
    },
    methods: {
        collectSearch(){
            return {
            module: 'balanceReport',
                elements: {
                    sdate: this.sdate,
                    edate: this.edate,
                }
            }
        },
        getRep(){
            const post = new FormData();
            post.append('type',"getBReport");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[account]',this.account);
            post.append('data[branchid]',this.branchid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.accountrows = response.data.results.data;
                    this.totals = response.data.results.totals;
                }
            })
        },
        getAccounts(){
            this.getRep();
            // const post = new FormData();
            // post.append('type',"getAccoutns");
            // post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            // post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            // post.append('data[sdate]',this.sdate);
            // post.append('data[edate]',this.edate);
            // axios.post(
            //     this.$store.state.SAMCOTEC.r_path , post
            // ).then((response) => {
            //     const res = response.data;
            //     // // console.log(res);
            //     this.accounts = res.results.data;
            // })
        },  
        getReport(){
            const post = new FormData();
            post.append('type',"getBReport");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[account]',this.account);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                if(typeof res.results.data !== 'undefined')
                {
                    this.accountrows = res.results.data;
                    this.totals = res.results.totals;
                }
            })
        }
    },
}
</script>